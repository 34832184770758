import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import { registerSW } from "ftl-core"

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
)

registerSW("/service-worker.js")
