// import { createEffect, attach, createStore } from "effector"
import { AxiosResponse } from "axios"
import { AuthClient } from "ftl-core"

//types
import {
  BasicResponse,
  createCountryRepository,
  createEntityRepository,
  createProfileRepository,
  createRepositoryInstance,
  createUtilsRepository,
  ListResponse,
} from "ftl-core"
import { Brand, BrandRequest } from "../types/entities/brand"
import { CategoryTag, CategoryTagRequest } from "../types/entities/categoryTag"
import { Organization, OrganizationRequest } from "../types/entities/organization"
import { Person, PersonRequest } from "../types/entities/person"
import { Role, RoleRequest } from "../types/entities/role"
import { Tag, TagRequest } from "../types/entities/tag"
import { User, UserRequest } from "../types/entities/user"
import { TrendReport } from "../types/reports/trend"
import { ReportSeries } from "../types/reports/reportSeries"
import { RatingReport } from "../types/reports/rating"
import { HeatmapResponse } from "../types/reports/heatmap"
import { Department, DepartmentRequest } from "../types/entities/department"
import { City, CityRequest } from "../types/entities/city"
import { Employee, EmployeeRequest } from "../types/entities/employee"
import { Position, PositionRequest } from "../types/entities/position"
import { Attendance, AttendanceRequest } from "../types/entities/attendance"
import { Manager } from "../types/entities/manager"

enum Versions {
  v1 = "v1",
  v2 = "v2",
}

export const apiAuthBaseUrl = process.env.REACT_APP_FTL_AUTH_BASE_URL
  ? process.env.REACT_APP_FTL_AUTH_BASE_URL
  : "https://consumers.auth.first.ftl-dev.ru"
export const apiCrmBaseUrl = process.env.REACT_APP_FTL_CRM_BASE_URL
  ? process.env.REACT_APP_FTL_CRM_BASE_URL
  : "http://localhost:42060"
export const apiAdminBaseUrl = process.env.REACT_APP_FTL_ADMIN_BASE_URL
  ? process.env.REACT_APP_FTL_ADMIN_BASE_URL
  : "http://localhost:8080"

export const HrBaseUrl =
  process.env.REACT_APP_ENV === "dev"
    ? `https://consumers.hr.first.ftl-dev.ru/api/${Versions.v1}`
    : `https://consumers.hr.first.ftl-dev.ru/api/${Versions.v1}`

export const ChainBaseUrl =
  process.env.REACT_APP_ENV === "dev"
    ? `https://consumers.chain.first.ftl-dev.ru/api/${Versions.v1}`
    : `https://consumers.chain.first.ftl-dev.ru/api/${Versions.v1}`

export const baseCrmUrl = `${apiCrmBaseUrl}/api/${Versions.v1}`
export const baseAuthUrl = `${apiAuthBaseUrl}/api/${Versions.v1}`

export const repository = createRepositoryInstance({
  baseUrl: baseCrmUrl,
  authErrors: {
    reasonKey: "reason",
    tokenExpired: ["ACCESS_TOKEN_EXPIRED"],
    permissionInsufficient: ["CREDENTIAL_PERMISSION_INSUFFICIENT"],
    needToLogin: [
      "CREDENTIAL_BLOCKED",
      "ACCESS_TOKEN_INACTIVATED",
      "ACCESS_TOKEN_NOT_EXIST",
      "ACCESS_TOKEN_REFRESHED",
      "ACCESS_TOKEN_MODIFIED",
      "ACCESS_TOKEN_MALFORMED",
    ],
  },
  tokenRefresh: () => AuthAPI.refresh(),
})

export const AuthAPI = new AuthClient(
  repository,
  {
    signIn: `${apiAuthBaseUrl}/not-secure/api/v1/auth/login/email`,
    signOut: `${apiAuthBaseUrl}/not-secure/api/v1/auth/logout`,
    refresh: `${apiAuthBaseUrl}/not-secure/api/v1/auth/refresh`,
  },
  {
    loginPropName: "email",
  },
)



export const HrRepository = createRepositoryInstance({
  baseUrl: HrBaseUrl,
  tokenRefresh: () => AuthAPI.refresh(),
})

export const ChainRepository = createRepositoryInstance({
  baseUrl: ChainBaseUrl,
  tokenRefresh: () => AuthAPI.refresh(),
})

export const UtilsAPI = createUtilsRepository(repository)
export const HrUtilsAPI = createUtilsRepository(HrRepository)
export const ChainUtilsAPI = createUtilsRepository(ChainRepository)

//Profile

export const ProfileAPI = createProfileRepository(repository)

//Country

export const CountryAPI = createCountryRepository(repository, UtilsAPI)

//Picture

export const PictureAPI = Object.freeze({
  post: (request: FormData): Promise<AxiosResponse<{ result: string }>> => {
    return repository.post(`pictures`, request)
  },
})

//Person
const normalizePersonPayload = (
  payload: Person | Person<"PATCH">,
): typeof payload => {
  const newPayload = {
    fullName: payload.fullName,
    birthday: payload.birthday,
    email: payload.email,
    gender: payload.gender,
    phone: payload.phone,
    avatarId: payload.avatarId,
    registerSource: payload.registerSource,
    referralPersonId: payload.referralPersonId,
    idType: payload.idType,
  }
  if ("registrationDate" in payload && payload.registrationDate) {
    return {
      ...newPayload,
      tagIds: payload.tagIds,
      socialLinkIds: payload.socialLinkIds,
      registrationDate: payload.registrationDate,
    }
  } else return newPayload
}

export const PersonAPI = Object.freeze({
  ...createEntityRepository<PersonRequest,
    Person<"GET">,
    Person,
    Person<"PATCH">>({
      repositoryInstance: repository,
      normalizeEntityPayload: normalizePersonPayload,
      basePath: "persons",
      utils: UtilsAPI,
    }),
  deleteAvatar: (id: string) => {
    return repository.delete(`persons/${id}/profile-image`)
  },
  delete: (id: string) => {
    return repository.delete(`persons/${id}`)
  },
  updateTags: (id: string, newTags: string[]) => {
    return repository.post(`persons/${id}/update-tags`, {
      tagIds: newTags,
    })
  },
})

//Tag
const normalizeTagPayload = (payload: Tag | Tag<"PATCH">): typeof payload => ({
  name: payload.name,
  description: payload.description,
  categoryTagId: payload.categoryTagId,
})

export const TagAPI = Object.freeze({
  ...createEntityRepository<TagRequest, Tag<"GET">, Tag, Tag<"PATCH">>({
    repositoryInstance: repository,
    normalizeEntityPayload: normalizeTagPayload,
    basePath: "tags",
    utils: UtilsAPI,
    defaultListParams: {
      offset: 0,
      limit: 1000,
      sortDirection: "DESC",
      sortName: "createdAt",
    },
  }),
  delete: (id: string) => {
    return repository.delete(`tags/${id}`)
  },
})

//CategoryTag
const normalizeCategoryTagPayload = (
  payload: CategoryTag | CategoryTag<"PATCH">,
): typeof payload => ({
  name: payload.name,
  description: payload.description,
  parentCategoryTagId: payload.parentCategoryTagId,
})

export const CategoryTagAPI = Object.freeze({
  ...createEntityRepository<CategoryTagRequest,
    CategoryTag<"GET">,
    CategoryTag,
    CategoryTag<"PATCH">>({
      repositoryInstance: repository,
      normalizeEntityPayload: normalizeCategoryTagPayload,
      basePath: "category-tags",
      utils: UtilsAPI,
      defaultListParams: {
        offset: 0,
        limit: 1000,
        sortDirection: "DESC",
        sortName: "createdAt",
      },
    }),
  delete: (id: string) => {
    return repository.delete(`category-tags/${id}`)
  },
})

const normalizeUserPayload = (
  payload: User | User<"PATCH">,
  type: "post" | "patch" = "post",
) => {
  const normalized: User | User<"PATCH"> = {
    accountStatus: payload.accountStatus,
    countryId: payload.countryId,
    email: payload.email,
    firstName: payload.firstName,
    lastName: payload.lastName,
    secondName: payload.secondName,
    phoneNumber: payload.phoneNumber,
    profileImageId: payload.profileImageId,
    roleIds: payload.roleIds,
  }
  if (payload.password && type === "post")
    normalized.password = payload.password

  return normalized
}

export const UsersAPI = Object.freeze({
  ...createEntityRepository<UserRequest, User<"GET">, User, User<"PATCH">>({
    repositoryInstance: repository,
    normalizeEntityPayload: normalizeUserPayload,
    basePath: "users",
    utils: UtilsAPI,
  }),
  deleteAvatar: (id: string) => {
    return repository.delete(`users/${id}/profile-image`)
  },
  changePassword: (
    id: string,
    newPassword: string,
  ): Promise<AxiosResponse<BasicResponse>> => {
    return repository.post(`users/${id}/password`, { newPassword })
  },
})

//Roles

export const RoleAPI = createEntityRepository<RoleRequest, Role>({
  repositoryInstance: repository,
  basePath: "roles",
  utils: UtilsAPI,
})

//Organization

const normalizeOrganizationPayload = (payload: Organization): Organization => ({
  activeStatus: payload.activeStatus,
  name: payload.name,
})

export const OrganizationAPI = createEntityRepository<OrganizationRequest,
  Organization<"GET">,
  Organization,
  Organization<"PATCH">>({
    repositoryInstance: repository,
    normalizeEntityPayload: normalizeOrganizationPayload,
    basePath: "organizations",
    utils: UtilsAPI,
  })

//Brands

const normalizeBrandPayload = (payload: Brand): Brand => ({
  name: payload.name,
  activeStatus: payload.activeStatus,
  logoId: payload.logoId,
  organizationId: payload.organizationId,
})

export const BrandAPI = createEntityRepository<BrandRequest,
  Brand<"GET">,
  Brand,
  Brand<"PATCH">>({
    repositoryInstance: repository,
    normalizeEntityPayload: normalizeBrandPayload,
    basePath: "brands",
    utils: UtilsAPI,
  })


// // Attendence
// const normalizeAttendancePayload = (
//   payload: AttendanceCommonResponse | AttendanceCommonResponse<"PATCH">,
// ): Omit<AttendanceCommonResponse | AttendanceCommonResponse<"PATCH">, "id" | "createdAt"> => {
//   const normalized: any = {
//     departmentId: payload.departmentId,
//     departmentName: payload.departmentName,
//     needConfirm: payload.needConfirm,
//     attendanceList: payload.attendanceList
//   }
//   return normalized
// }
//
// export const AttendanceAPI = Object.freeze({
//   ...createEntityRepository<AttendanceCommonRequest,
//     AttendanceCommonResponse<"GET">,
//     AttendanceCommonResponse,
//     AttendanceCommonResponse<"PATCH">>({
//     repositoryInstance: HrRepository,
//     normalizeEntityPayload: normalizeAttendancePayload,
//     basePath: "attendances",
//     utils: HrUtilsAPI,
//   }),
//   edit: (values: any) =>
//     HrRepository.put(`${HrBaseUrl}/attendances/${values.id}`, values),
//   delete: (id: string) => HrRepository.delete(`${HrBaseUrl}/attendances/${id}`),
//   getAttendances: (): Promise<AxiosResponse<GetListResponse<AttendanceCommonResponse<"GET">>>> => {
//     return HrUtilsAPI.getWithParams(`attendances/by-manager`)
//   },
// })

const normalizeAttendancePayload = (
  payload: Attendance | Attendance<"PATCH">
): Omit<Attendance | Attendance<"PATCH">, "id" | "createdAt"> => {
  const normalized: any = {
    id: payload.id,
    comment: payload.comment,
    departmentId: payload.departmentId,
    managerConfirm: payload.managerConfirm,
    employeeId: payload.employeeId,
    positionId: payload.positionId,
    duration: payload.duration,
    status: payload.status,
    syncStatus: payload.syncStatus,
    uuid: payload.uuid,
    dateEnd: payload.dateEnd,
    dateStart: payload.dateStart,
  }
  return normalized
}
export const AttendanceAPI = Object.freeze({
  ...createEntityRepository<
    AttendanceRequest,
    Attendance<"GET">,
    Attendance,
    Attendance<"PATCH">
  >({
    repositoryInstance: repository,
    normalizeEntityPayload: normalizeAttendancePayload,
    basePath: "attendances",
    utils: UtilsAPI,
  }),

  attendanceByDepartmentId: (
    id: string,
  ): Promise<AxiosResponse<BasicResponse<any>>> => {
    return UtilsAPI.getWithParams(
      `attendances/by-department/${id}`,
      {},
      {},
    )
  },
  create: (payload: Attendance):
    Promise<AxiosResponse<BasicResponse<Attendance<"GET">>>> =>
    repository.post("attendances", normalizeAttendancePayload(payload)),
  edit: (id: string | undefined, payload: Attendance):
    Promise<AxiosResponse<BasicResponse<Attendance<"GET">>>> =>
    repository.put(`attendances/${id}`, normalizeAttendancePayload(payload)),
})


//Department
const normalizeDepartmentPayload = (
  payload: Department<"POST"> | Department<"PATCH">,
): Department => ({
  id: payload.id,
  title: payload.title,
  orgStructureId: payload.orgStructureId,
  warehouseId: payload.warehouseId,
  cityId: payload.cityId,
  priceCategoryId: payload.priceCategoryId,
  status: payload.status,
  legalUnitId: payload.legalUnitId,
  point: payload.point,
  code: payload.code,
  corpStructureId: payload.corpStructureId,
  locationPlans: payload.locationPlans,
  startWorkDate: payload.startWorkDate,
})

export const DepartmentAPI = Object.freeze({
  ...createEntityRepository<DepartmentRequest, Department<"GET">>({
    repositoryInstance: repository,
    basePath: "departments",
    utils: UtilsAPI,
    normalizeEntityPayload: normalizeDepartmentPayload,
  }),
})

//Employee
const normalizeEmployeePayload = (
  payload: Employee | Employee<"PATCH">
): Omit<Employee | Employee<"PATCH">, "id" | "createdAt"> => {
  const normalized: any = {
    id: payload.id,
    createdAt: payload.createdAt,
    deleted: payload.deleted,
    fullName: payload.fullName,
    emails: payload.emails,
    personalNumber: payload.personalNumber,
    status: payload.status,
    positions: payload.positions,
    updatedAt: payload.updatedAt,
    phones: payload.phones,
  }
  return normalized
}
export const EmployeeAPI = Object.freeze({
  ...createEntityRepository<
    EmployeeRequest,
    Employee<"GET">,
    Employee,
    Employee<"PATCH">
  >({
    repositoryInstance: repository,
    normalizeEntityPayload: normalizeEmployeePayload,
    basePath: "employees",
    utils: UtilsAPI,
  }),

  employeeById: (
    id: string,
  ): Promise<AxiosResponse<BasicResponse<any>>> => {
    return UtilsAPI.getWithParams(
      `employees/${id}`,
      {},
      {},
    )
  },

  employeeByDepartmentId: (
    id: string,
  ): Promise<AxiosResponse<BasicResponse<any>>> => {
    return repository.get(
      `employees?departmentId=${id}`
    )
  },
  
  getEmployeeById: (
    id: string
  ): Promise<AxiosResponse<BasicResponse<any>>> => {
    return repository.get(
      `employees/${id}`
    )
  },
})

//Position
const normalizePositionPayload = (
  payload: Position | Position<"PATCH">
): Omit<Position | Position<"PATCH">, "id" | "createdAt"> => {
  const normalized: any = {
    id: payload.id,
    createdAt: payload.createdAt,
    deleted: payload.deleted,
    updatedAt: payload.updatedAt,
    condition: payload.condition,
    description: payload.description,
    title: payload.title,
    categoryId: payload.categoryId,
  }
  console.log(normalized)
  return normalized
}
export const PositionAPI = Object.freeze({
  ...createEntityRepository<
    PositionRequest,
    Position<"GET">,
    Position,
    Position<"PATCH">
  >({
    repositoryInstance: repository,
    normalizeEntityPayload: normalizePositionPayload,
    basePath: "positions",
    utils: UtilsAPI,
  }),

  positionById: (
    id: string,
  ): Promise<AxiosResponse<BasicResponse<any>>> => {
    return UtilsAPI.getWithParams(
      `positions/${id}`,
      {},
      {},
    )
  },
  get: ():
    Promise<AxiosResponse<BasicResponse<{ count: number, items: Position[] }>>> =>
    repository.get("positions"),
})


//Cities
const normalizeCityPayload = (payload: City): City => ({
  name: payload.name,
  code: payload.code,
  point: payload.point,
})

export const CityAPI = Object.freeze({
  ...createEntityRepository<CityRequest, City<"GET">>({
    repositoryInstance: ChainRepository,
    basePath: "cities",
    utils: ChainUtilsAPI,
    normalizeEntityPayload: normalizeCityPayload,
  }),
})

//Managers
export const ManagersAPI = Object.freeze({
  managerById: (
    id: string,
  ): Promise<AxiosResponse<BasicResponse<Manager>>> => {
    return ChainUtilsAPI.getWithParams(
      `managers/${id}`,
      {},
      {},
    )
  },
})

//Reports
export const ReportsAPI = Object.freeze({

  restById: (
    id: string,
  ): Promise<AxiosResponse<BasicResponse<any>>> => {
    return UtilsAPI.getWithParams(
      `chain/rest/${id}`,
      {},
      {},
    )
  },
  cityById: (
    id: string,
  ): Promise<AxiosResponse<BasicResponse<any>>> => {
    return UtilsAPI.getWithParams(
      `chain/city/${id}`,
      {},
      {},
    )
  },
  trendSum: (
    type: string,
    year?: number,
    month?: number,
  ): Promise<AxiosResponse<BasicResponse<TrendReport>>> => {
    return UtilsAPI.getWithParams(
      `trend`,
      {
        type: type,
        year: year,
        month: month,
      },
      {},
    )
  },


  trendSumByExternalId: (
    type: string,
    externalId: string,
    year?: number,
    month?: number,
  ): Promise<AxiosResponse<BasicResponse<TrendReport>>> => {
    return UtilsAPI.getWithParams(
      `trend/external-id`,
      {
        type: type,
        externalId: externalId,
        year: year,
        month: month,
      },
      {},
    )
  },

  reportSeries: (
    type: string,
  ): Promise<AxiosResponse<BasicResponse<ReportSeries>>> => {
    return UtilsAPI.getWithParams(
      `report-series`,
      {
        type: type,
      },
      {},
    )
  },

  rating: (
    type: "CITY_SUM" | "CITY_COUNT" | "REST_SUM" | "REST_COUNT",
    year: number,
    month: number,
  ): Promise<AxiosResponse<BasicResponse<RatingReport[]>>> => {
    return UtilsAPI.getWithParams(
      `rating`,
      {
        type: type,
        year: year,
        month: month,
      },
      {},
    )
  },

  ratingByExternalId: (
    type: "REST_SUM" | "REST_COUNT",
    year: number,
    month: number,
    externalId: string,
  ): Promise<AxiosResponse<BasicResponse<RatingReport[]>>> => {
    return UtilsAPI.getWithParams(
      `rating/external-id`,
      {
        type: type,
        year: year,
        month: month,
        externalId: externalId,
      },
      {},
    )
  },

  heatmap: (
    // type: "REST_SUM_HEATMAP" | "REST_COUNT_HEATMAP" | "REST_AVG_HEATMAP",
    type: string,
    year: number,
    month: number,
    day: number,
    externalId: string,
  ): Promise<AxiosResponse<BasicResponse<HeatmapResponse>>> => {
    return UtilsAPI.getWithParams(
      "heatmap",
      {
        type: type,
        year: year,
        month: month,
        day: day,
        externalId: externalId,
      },
      [],
    )
  },
})

