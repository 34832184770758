import {
  BasicResponse,
  DetailPageProps,
  FTLDateUtils,
  handleError,
  snackbarService,
  useDetailPage,
} from "ftl-core"
import { FTLDetailPage } from "ftl-dashboards-templates"
import {
  FTLBreadcrumbs,
  FTLDayPicker,
  FTLLoader,
  FTLPageHeader,
  FTLSelect,
  FTLTextField,
  FTLToolbar,
} from "ftl-uikit"
import { Box } from "@material-ui/core"
import { attendanceSchema, initState } from "./Model"
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers"
import moment from "moment"
import MomentUtils from "@date-io/moment"
import { useEffect, useState } from "react"
import { AttendanceAPI, EmployeeAPI, PositionAPI } from "../../repository"
import { Attendance } from "../../types/entities/attendance"
import { Employee } from "../../types/entities/employee"

interface EmployeeResultArray {
  value: string
  label: string
}

interface PositionResultArray {
  value: string
  label: string
}

const AttendanceDetail = ({ pageType }: DetailPageProps) => {
  const {
    isFetching,
    formik,
    history,
    isFormNotChanged,
    setEntityData,
    entityData,
  } = useDetailPage({
    pageType,
    initialValues: initState,
    validationSchema: attendanceSchema,
    getDetails: AttendanceAPI.getById,
    handleFetchedEntity: async (attendance) => {
      const data = {
        ...formik.values,
        ...attendance.data.result,
      }
      await formik.setValues(data, false)
      setEntityData(data)
    },
    onSubmit: async (values) => {
      try {
        switch (pageType) {
          case "new":
            await AttendanceAPI.create(values as Attendance<"POST">)
            await formik.setValues(
              {
                ...formik.values,
              },
              false
            )
            snackbarService.show({
              variant: "success",
              message: "Явка успешно создана",
            })
            break
          case "edit":
            if (values.dateEnd && values.status !== "NEED_CONFIRM") {
              values.status = "CLOSED";
            }
            await AttendanceAPI.edit(entityData.id, values as Attendance<"GET">)
            snackbarService.show({
              variant: "success",
              message: "Параметры явки успешно обновлены",
            })
            break
        }
        history.push("/attendance")
      } catch (error: any) {
        handleError({
          error,
          defaultError: "Произошла ошибка при отправке данных",
        })
      } finally {
        formik.setSubmitting(false)
      }
    },
  })
  //@ts-ignore
  const [departmentId, setDepartmentId] = useState([history.location.state.departmentId,])

  const [employee, setEmployee] = useState<any>()
  const [employees, setEmployees] = useState<EmployeeResultArray[]>([
    { value: "0", label: "Не найдено" },
  ])
  const [position, setPosition] = useState<any>()
  const [positionsArray, setPositionsArray] = useState<PositionResultArray[]>([
    { value: "0", label: "Не найдено" },
  ])

  const [currentPos, setCurrentPos] = useState<string>()
  const [currentEmp, setCurrentEmp] = useState<string>()

  const onStartDateChange = (date: any, value: any) => {
    if (date) {
      formik.setFieldValue("dateStart", date.format("YYYY-MM-DDTHH:mm"))
    }
  }

  const onEndDateChange = (date: any, value: any) => {
    if (date) {
      formik.setFieldValue("dateEnd", date.format("YYYY-MM-DDTHH:mm"))
    }
  }

  const dateFormatter = (str: string) => {
    return str
  }

  useEffect(() => {
    if (pageType === "new") {
      PositionAPI.get().then((response) => {
        let data = response.data.result
        const positionResultArray = Promise.all(
          data.items.map((item) => {
            return new Promise<BasicResponse<PositionResultArray>>(
              async (resolve) => {
                let arr = {
                  value: "",
                  label: "",
                }

                arr.value = item.id
                arr.label = item.title

                resolve({ result: arr })
              }
            )
          })
        )
        positionResultArray.then((res) => {
          setPositionsArray(res.map((item) => item.result))
        })
      })
    } else if (pageType === "edit") {
      PositionAPI.getById(entityData.positionId).then((response) => {
        let data = response.data.result
        setPosition(data)
      })
    }
  }, [entityData.positionId])

  useEffect(() => {
    if (departmentId && pageType === "new") {
      let resultarr: any = []

      const response = Promise.all(
        departmentId.map((item: any) => {
          return new Promise<
            BasicResponse<{ count: number; items: Employee[] }>
          >(async (resolve) => {
            const emp = await EmployeeAPI.employeeByDepartmentId(item)
            resolve({ result: emp.data.result })
          })
        })
      )

      response.then((res) => {
        let resArr: any[] = []
        resultarr = res.map((item) => item.result.items)
        resultarr.map((item: any) => {
          item.map((item1: any) => {
            resArr.push(item1)
          })
        })
        const employeeResultArray = Promise.all(
          resArr.map((item: any) => {
            return new Promise<BasicResponse<EmployeeResultArray>>(
              async (resolve) => {
                let obj1 = {
                  value: "",
                  label: "",
                }

                obj1.value = item.id
                obj1.label = `${item.fullName.lastName} ${item.fullName.firstName} ${item.fullName.secondName}`

                resolve({ result: obj1 })
              }
            )
          })
        )
        employeeResultArray.then((ress) => {
          setEmployees(ress.map((item) => item.result))
        })
      })
    } else {
      EmployeeAPI.getEmployeeById(entityData.employeeId).then(({ data }) => {
        setEmployee(data.result)
      })
    }
  }, [departmentId, entityData.employeeId])

  return (
    <FTLDetailPage<Attendance<"FORMIK">>
      formik={formik}
      headerComponent={
        <>
          <FTLBreadcrumbs
            items={[
              { label: "Явки", to: "/attendance" },
              {
                label:
                  pageType === "edit" ? entityData.employeeId : "Добавить явку",
                to: "#",
              },
            ]}
          />

          <FTLPageHeader
            title={pageType === "edit" ? entityData.employeeId : "Новая явка"}
            BoxProps={{
              mb: 6,
            }}
          />
        </>
      }
      toolbarComponent={
        <FTLToolbar
          position="sticky"
          onSaveBtnId="form"
          onCancel={() => history.push("/attendance")}
          async={formik.isSubmitting}
          disabled={isFormNotChanged || !formik.isValid}
          onSaveMessage="Сохранить"
        />
      }
    >
      {isFetching ? (
        <FTLLoader height="50vh" />
      ) : (
        <>
          <form
            noValidate
            id="form"
            onSubmit={(e) => {
              e.preventDefault()
              formik.handleSubmit()
            }}
          >
            <Box display="grid" mt={6} gridGap="24px" width="360px">
              <Box width="360px">
              {pageType === "new" ? (
                  <FTLSelect
                  searchable
                  searchInList
                  error={!!formik.errors.employeeId}
                  label="Сотрудник"
                  placeholder="Выберите сотрудника"
                  options={
                      employees
                      ? employees
                      : [{ value: "0", label: "Не найдено" }]
                  }
                  value={
                      employees
                      ? employees.find((item) => item.value === currentEmp)
                      : null
                  }
                  onChange={(e) => {
                      setCurrentEmp(e?.value)
                      formik.setFieldValue("employeeId", e?.value)
                  }}
                  />
              ) : (
                  <FTLTextField
                  label="Сотрудник"
                  readOnly
                  required
                  value={
                      employee && employee.fullName
                      ? `${employee.fullName.lastName} ${employee.fullName.firstName} ${employee.fullName.secondName}`
                      : null
                  }
                  />
              )}
              </Box>
              <Box width="360px">
                {pageType === "new" ? (
                  <FTLSelect
                    searchable
                    searchInList
                    error={!!formik.errors.positionId}
                    label="Должность"
                    placeholder="Должность"
                    options={
                      positionsArray
                        ? positionsArray
                        : [{ value: "0", label: "Не найдено" }]
                    }
                    value={
                      positionsArray
                        ? positionsArray.find(
                            (item) => item.value === currentPos
                          )
                        : null
                    }
                    onChange={(e) => {
                      setCurrentPos(e?.value)
                      formik.setFieldValue("departmentId", departmentId[0])
                      formik.setFieldValue("positionId", e?.value)
                    }}
                  />
                ) : (
                  <FTLTextField
                    label="Должность"
                    readOnly
                    required
                    value={position ? position.title : null}
                  />
                )}
              </Box>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <Box width="360px">
                  <KeyboardDateTimePicker
                    disabled={formik.values.status === "CONFIRMED"}
                    fullWidth
                    error={!!formik.errors.dateStart}
                    helperText={formik.errors.dateStart}
                    label="Дата и время начала"
                    inputVariant="outlined"
                    ampm={false}
                    showTodayButton={true}
                    value={formik.values.dateStart || null}
                    format="DD:MM:YYYY HH:mm"
                    // inputValue={formik.values.dateStart ? inpuStartValue : ""}
                    placeholder={moment().format("DD:MM:YYYY HH:mm")}
                    onChange={onStartDateChange}
                    rifmFormatter={dateFormatter}
                  />
                </Box>
                <Box width="360px">
                  <KeyboardDateTimePicker
                    disabled={formik.values.status === "CONFIRMED"}
                    fullWidth
                    error={!!formik.errors.dateEnd}
                    helperText={formik.errors.dateEnd}
                    label="Дата и время окончания"
                    inputVariant="outlined"
                    ampm={false}
                    showTodayButton={true}
                    value={formik.values.dateEnd || null}
                    format="DD:MM:YYYY HH:mm"
                    // inputValue={formik.values.dateEnd ? inputEndValue : ""}
                    placeholder={moment().format("DD:MM:YYYY HH:mm")}
                    onChange={onEndDateChange}
                    rifmFormatter={dateFormatter}
                  />
                </Box>
              </MuiPickersUtilsProvider>
            </Box>
          </form>
        </>
      )}
    </FTLDetailPage>
  )
}

export default AttendanceDetail
