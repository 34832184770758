import { Denormalized, FiltersState, requiredMessage } from "ftl-core"
import React from "react"
import { Attendance } from "../../types/entities/attendance"
import * as Yup from "yup"
import moment from "moment"

export const initState: Attendance = {
  comment: "",
  departmentId: "",
  managerConfirm: "",
  employeeId: "",
  positionId: "",
  status: "CLOSED"
}

export const attendanceSchema = Yup.object({
  employeeId: Yup.string().required(requiredMessage),
  positionId: Yup.string().required(requiredMessage),
  dateStart: Yup.date().typeError("Введена некорректная дата").max(
    moment().endOf('day'), "Дата начала не может быть больше сегодня").required(requiredMessage),
  dateEnd: Yup.date().typeError("Введена некорректная дата").min(
    Yup.ref('dateStart'),
    "Дата окончания должна быть больше даты начала"
  ).max(moment().endOf('day'), "Дата окончания не может быть больше сегодня"),
})

type normalizeFuncType = (
  { items }: Denormalized<Attendance>,
  context: {
    setData: React.Dispatch<Attendance[]>
    meta?: any
  },
) => Attendance[]

export const normalizeAttendanceFilters = (filters: FiltersState) => ({
  query: filters.query,
})
export const normalizeAttendance: normalizeFuncType = ({ items }, context) => {
  const normalizeDataOfTable = items.map((item) => {
    return {
      ...item,
    }
  })
  return normalizeDataOfTable
}
