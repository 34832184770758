import { lazy, Suspense } from "react"
import { Switch } from "react-router-dom"
import { FTLErrorPage } from "ftl-uikit"
import { PrivateRoute } from "ftl-core"
import AttendanceDetail from "./pages/Attendance/Detail"

//Graph
const ChainLevelReport = lazy(() => import("./pages/Reports/ChainLevelReport"))
const CityRatingReport = lazy(() => import("./pages/Reports/CityRatingReport"))
const RestRatingReport = lazy(() => import("./pages/Reports/RestRatingReport"))
const CityReport = lazy(() => import("./pages/Reports/CityReport"))
const RestReport = lazy(() => import("./pages/Reports/RestReport"))
const DepartmentsList = lazy(() => import("./pages/Departments/List"))
const AttendanceList = lazy(() => import("./pages/Attendance/List"))

export const Routes = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <PrivateRoute exact path="/reports">
          <ChainLevelReport />
        </PrivateRoute>
        <PrivateRoute exact path="/city-rating">
          <CityRatingReport />
        </PrivateRoute>
        <PrivateRoute exact path="/rest-rating">
          <RestRatingReport />
        </PrivateRoute>
        <PrivateRoute exact path="/city/:id">
          <CityReport />
        </PrivateRoute>
        <PrivateRoute exact path="/rest/:id">
          <RestReport />
        </PrivateRoute>

        <PrivateRoute exact path="/attendance">
          <AttendanceList />
        </PrivateRoute>

        <PrivateRoute exact path="/attendance/departments">
          <DepartmentsList />
        </PrivateRoute>

        <PrivateRoute exact path="/attendance/new">
          <AttendanceDetail pageType="new" />
        </PrivateRoute>
        <PrivateRoute exact path="/attendances/:id">
          <AttendanceDetail pageType="edit" />
        </PrivateRoute>

        <PrivateRoute path={["*", "/404"]}>
          <FTLErrorPage
            title="Страницы не существует"
            message="Наверное, вы перешли по неправильной ссылке."
          />
        </PrivateRoute>
      </Switch>
    </Suspense>
  )
}
