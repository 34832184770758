import { UIMain } from "ftl-dashboards-templates"
import { Routes } from "./Routes"
import { AuthAPI } from "./repository"
import { basicReducer, initialState } from "ftl-core"
import { createStore } from "redux"
import { OrderServiceIcon } from "ftl-uikit/dist/components/icons"
import { theme } from "ftl-uikit"
import PersonPinCircleIcon from "./shared/util/icons/PersonPinCircleIcon"

const store = createStore(
  basicReducer,
  initialState,
  //@ts-ignore
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
)
const title = process.env.REACT_APP_FTL_PROJECT_TITLE
  ? process.env.REACT_APP_FTL_PROJECT_TITLE
  : "FTL Marketing ARM"

export const App = () => {
  return (
    <UIMain
      redirectOnAuthUrl="/reports"
      project={{ title }}
      theme={theme}
      AuthClient={AuthAPI}
      StoreProviderProps={{
        store,
        getProfile: () => {
          //заглушка: единственный способ избежать вытягивание профиля при загрузке приложения
          return Promise.resolve({
            data: {
              result: {
                id: "",
                createdAt: "",
                email: "",
                firstName: "",
                lastName: "",
                accountStatus: "",
              },
            },
            status: 200,
            statusText: "OK",
            headers: {},
            config: {},
          })
        },
      }}
      BaseLayoutProps={{
        onMenuClick: async () => {
        },
        sideMenuItems: [
          {
            to: "/reports",
            label: "Общий отчет",
            icon: <OrderServiceIcon />,
          },
          {
            to: "/city-rating",
            label: "Рейтинг городов",
            icon: <OrderServiceIcon />,
          },
          {
            to: "/rest-rating",
            label: "Рейтинг ресторанов",
            icon: <OrderServiceIcon />,
          },
          {
            to: "/attendance",
            label: "Явки",
            icon: <PersonPinCircleIcon />,
          },
        ],
      }}
    >
      <Routes />
    </UIMain>
  )
}

export default App
